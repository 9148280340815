<template>
  <div class="app-container">
    <div class="block">
      <div class="avatar-wrapper">
        <van-image
          width="60"
          height="60"
          class="avatar"
          round
          :src="wechat_avatar"
        />
        <div class="nickname">{{ $Base64.decode(wechat_nickname) }} <van-tag type="primary">{{clientType[userinfo.client_type]}}</van-tag></div>
      </div>
      <van-grid square clickable :column-num="3">
        <van-grid-item
          text="合伙人申请"
          icon="edit"
          @click="$router.push({ name: 'Register' })"
        />
        <van-grid-item
          text="申请销售顾问"
          icon="edit"
          @click="$router.push({ name: 'Register', query: {sale: 3} })"
        />
        <van-grid-item
          text="更新名片"
          icon="idcard"
          @click="$router.push({ name: 'MyCardEdit' })"
          v-if="userinfo.card_status === 1"
        />
        <van-grid-item
          text="制作海报"
          icon="newspaper-o"
          @click="$router.push({ name: 'KvEdit' })"
          v-if="userinfo.card_status === 1"
        />
        <van-grid-item
          text="动态列表"
          icon="description"
          @click="$router.push({ name: 'MediaList' })"
        />
        <van-grid-item
          text="活动列表"
          icon="description"
          @click="$router.push({ name: 'ActList' })"
        />
        <van-grid-item
          text="手机报表"
          icon="phone-o"
          url="https://h5.hk.zhongheinfo.com"
        />
      </van-grid>
      <div class="foot">
        <van-icon name="warning-o" size="14" style="margin-right: 3px" /> 本页面仅限内部员工使用，不宜外传
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from 'js-cookie'
export default {
  data () {
    return {
      wechat_avatar: Cookies.get('wechat_avatar'),
      wechat_nickname: Cookies.get('wechat_nickname'),
      client_type: Number(Cookies.get('client_type')),
      userinfo: {
        client_type: 2
      },
      clientType: { 0: '无', 1: '普通客户', 2: '外部代表', 3: '内部员工' }
    }
  },
  mounted(){
    this.getDataList()
  },
  methods: {
    getDataList(){
      this.$axios.post('/wxc/client/detail').then(res => {
        this.userinfo = res.data
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.block {
  .head-title {
    margin: 0;
    padding: 20px 16px 16px;
    color: rgba(69, 90, 100, 0.6);
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }
}
.avatar-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  .nickname {
    padding: 10px;
  }
}
.btn {
  padding: 0 20px;
}
.foot{
  font-size: 12px;
  padding: 40px 20px;
  color: rgba(69, 90, 100, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>